<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <Toast />
        <loading
    :active="isLoadingModel"
    :can-cancel="true"
    
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img src="images/download.png" alt="Mahajyoti logo" class="mb-5">
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <!-- <div class="text-center mb-5">
                        <img src="layout/images/avatar.png" alt="Image" height="50" class="mb-3">
                        <div class="text-900 text-3xl font-medium mb-3">Welcome, Isabel!</div>
                        <span class="text-600 font-medium">Sign in to continue</span>
                    </div> -->
                
                    <div class="w-full md:w-10 mx-auto">
                        <label for="email1" class="block text-900 text-xl font-medium mb-2">Username</label>
                        <InputText id="email1" v-model="email" type="text" class="w-full mb-3" placeholder="Username" style="padding:1rem;" />
                        <!-- <InlineMessage v-if="email==''">Username is required</InlineMessage> -->

                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <Password id="password1" v-model="password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
                        <!-- <InlineMessage v-if="password==''">Password is required</InlineMessage> -->

                        <label for="type" class="block text-900 text-xl font-medium mb-2">User Type</label>
                        <Dropdown id="state" v-model="user_type" :options="dropdownItems" :toggleMask="true" optionLabel="name" placeholder="Select One" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Dropdown>
                        <InlineMessage v-if="show==true">UserType is required</InlineMessage>
                
                        <div class="flex align-items-center justify-content-between mb-5">
                            <div class="flex align-items-center">
                                <Checkbox id="rememberme1" v-model="checked" :binary="true" class="mr-2"></Checkbox>
                                <label for="rememberme1">Remember me</label>
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">Forgot password?</a>
                        </div>
                        <Button label="Sign In" class="w-full p-3 text-xl" @click="Login"> </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apis from "@/apis";
export default {
    data() {
        return {
            isLoadingModel:false,
			fullPage:true,
            email: '',
            password: '',
            checked: false,
            show:false,
            user_type:'',
            dropdownItems: [
					{name: 'Admin', value: 'admin'},
					{name: 'Teacher', value: 'teacher'},
					{name: 'Assistent Teacher', value: 'assteacher'},
                    {name: 'Student', value: 'student'},
                    {name: 'Network Administrator', value: 'network'}
				],
        }
    },
    methods:{
      Login()
      {
        
        if(this.email==="" ||this.email===null ||this.email===undefined)
        {
            //alert("Please Enter Username");
            this.$toast.add({severity:'error', summary: 'Error Message', detail:'Please Enter Username', life: 3000});
            return false;
        }
        if(this.password==="" ||this.password===null ||this.password===undefined)
        {
            //alert("Please Enter Password");
            this.$toast.add({severity:'error', summary: 'Error Message', detail:'Please Enter Password', life: 3000});
            return false;
        }
        if(this.user_type==="" ||this.user_type===null ||this.user_type===undefined)
        {  
            // this.show=true;
            // alert("Please Select User Type");
            this.$toast.add({severity:'error', summary: 'Error Message', detail:'Please Select User Type', life: 3000});
            return false;
        }
        var data = 
         {
                "USERNAME":this.email,  
                "password":this.password, 
                "type":this.user_type.value
         };
         this.isLoadingModel = true;
		var promise = apis.login(data);
        promise
        .then(response => { 
            this.isLoadingModel = false;
        if(response.status==200)
        {
           
            localStorage.user_key = this.user_type.value;
            
            if(this.user_type.value == "admin")
            {
                 localStorage.token = response.data["token"]; // user type id
                 localStorage.id = response.data.admindata["admiN_ID"];
                 localStorage.full_name = response.data.admindata["firsT_NAME"];  
                 localStorage.email = response.data.admindata["email"]; 
                //  this.$session.start();
                // this.$session.set("token", token);
               
                this.$router.push('/dashboard');
            }
            else if(this.user_type.value == "teacher") 
            {
                 localStorage.token = response.data["token"];
                 localStorage.id = response.data.teacherdata["teacheR_ID"];
                 localStorage.full_name = response.data.teacherdata["firsT_NAME"];
                 localStorage.email = response.data.teacherdata["email"]; 

                // this.$session.start();
                // this.$session.set("user_login_type", 'Teacher');
                // this.$session.set("user_type", this.user_type.value);
                this.$router.push('/teacherhome');
            }
            else if(this.user_type.value == "assteacher") 
            {      
                localStorage.id = response.data.teacherdata["teacheR_ID"];
                localStorage.token = response.data["token"];
                localStorage.full_name = response.data.teacherdata["firsT_NAME"];
                localStorage.email = response.data.teacherdata["email"]; 
                // this.$session.start();
                // this.$session.set("user_login_type", 'assistant_teacher');
                // this.$session.set("user_type", this.user_type.value);
                this.$router.push('/ateacherhome');
            }
            else if(this.user_type.value == "network") 
            {      
                localStorage.id = response.data.networkuserdata["id"];
                localStorage.token = response.data["token"];
                localStorage.full_name = response.data.networkuserdata["name"];
                localStorage.email = response.data.networkuserdata["email"]; 
                // this.$session.start();
                // this.$session.set("user_login_type", 'assistant_teacher');
                // this.$session.set("user_type", this.user_type.value);
                this.$router.push('/dashboard');
            }
            else if(this.user_type.value == "student") 
            {      
                localStorage.id = response.data.studentdata["studenT_ID"];
                localStorage.token = response.data["token"];
                localStorage.full_name = response.data.studentdata["firsT_NAME"]+" "+response.data.studentdata["surname"];
                localStorage.email = response.data.studentdata["email"]; 
                localStorage.mobile = response.data.studentdata["mobile"]; 
                // this.$session.start();
                // this.$session.set("user_login_type", 'assistant_teacher');
                // this.$session.set("user_type", this.user_type.value);
                this.$router.push('/studentdashboard');
            }
        } 
        else if (response.status == 404 || response.status == 300) 
        {
              this.$toast.add({severity:'error', summary: 'Error Message', detail:'Invalid Login Credentials', life: 3000});
            return false;
        }  
            
            
        })
        .catch(error => {
            this.isLoadingModel = false;
            this.$toast.add({severity:'error', summary: 'Error Message', detail:'Invalid Login Credentials', life: 3000});
            return false;
            });
      }  
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>